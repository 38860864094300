import * as React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import logoLast9 from "../images/logo-last9.svg";
import logoMarkk from "../images/logo-markk.svg";
import logoQuinto from "../images/logo-quintoai.svg";
import logoTTS from "../images/logo-the-tossed-salad.svg";
import logoYolkshire from "../images/logo-yolkshire.svg";
import illustration from "../images/illustration.png";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const IndexPage = ({ data }) => {
  return (
    <Layout>
      {/* header start */}
      <div className="overflow-hidden bg-yellow-400 px-6 pt-6 pb-28 sm:px-0">
        <div className="relative mx-auto max-w-lg">
          {/* nav start */}
          <span className="relative z-10 flex gap-4">
            <Link to="/" className="text-base font-semibold text-black">
              Sahil Khan
            </Link>
            <Link
              to="/about"
              className="text-base font-semibold text-black/50 hover:text-black hover:underline">
              About
            </Link>
            <Link
              to="/blog"
              className="text-base font-semibold text-black/50 hover:text-black hover:underline">
              Blog
            </Link>
          </span>
          {/* nav end */}
          <img
            src={illustration}
            alt="illustration of Sahil Khan"
            className="absolute top-0 -right-36 w-96 sm:-top-6 sm:-right-52"
          />
        </div>
      </div>
      {/* header end */}

      {/* body start */}
      <div className="mx-auto max-w-lg overflow-hidden px-6 pt-12 sm:overflow-visible sm:px-0">
        <main className="prose prose-invert prose-a:text-gray-400 hover:prose-a:text-gray-200 prose-hr:border-gray-800">
          <p className="text-2xl font-bold text-neutral-100">
            I used to run a startup, a restaurant, a web magazine, and
            freelanced as a designer. Now I draw rectangles for startups.
          </p>

          <Link
            to="/about"
            className="not-prose flex rounded-2xl bg-neutral-900 p-3 no-underline transition-all">
            <span className="flex-1">About Me →</span>
            <span className="flex flex-initial gap-2">
              <img className="w-8" src={logoLast9} alt="Last9" />
              <img className="w-8" src={logoMarkk} alt="Markk" />
              <img className="w-8" src={logoQuinto} alt="Quinto" />
              <img className="w-8" src={logoTTS} alt="The Tossed Salad" />
              <img className="w-8" src={logoYolkshire} alt="Yolkshire" />
            </span>
          </Link>

          <hr />

          <div className="not-prose flex snap-x snap-mandatory flex-row flex-nowrap justify-start gap-3 overflow-x-auto rounded-t-2xl bg-neutral-900 py-3 px-3 lg:snap-none lg:justify-center lg:overflow-visible">
            {data.allMdx.nodes.map((node) => (
              <Link
                to={`/blog/${node.slug}`}
                key={node.id}
                className="relative flex aspect-[3/4] w-[60%] min-w-[60%] snap-center scroll-ml-3 flex-col gap-4 rounded-2xl bg-neutral-900 lg:w-[50%] lg:min-w-[50%]">
                <GatsbyImage
                  image={getImage(node.frontmatter.heroImage)}
                  alt={node.frontmatter.heroImageAlt}
                  className="absolute top-0 right-0 bottom-0 left-0 h-full w-full rounded-xl lg:rounded-2xl [&>*>*]:rounded-xl lg:[&>*>*]:rounded-2xl"
                />
                <div className="absolute top-0 right-0 bottom-0 left-0 z-10 flex h-full w-full flex-col justify-end gap-2 rounded-xl border border-slate-800 bg-gradient-to-t from-slate-900/75 to-slate-900/25 p-3 sm:p-4 lg:rounded-2xl">
                  <h1 className="text-xl font-bold text-gray-200 drop-shadow-[0_0_1px_rgba(0,0,0,0.25)] sm:text-2xl">
                    {node.frontmatter.title}
                  </h1>
                  <p className="text-sm drop-shadow-[0_0_1px_rgba(0,0,0,0.25)]">
                    {node.frontmatter.datePublished}
                  </p>
                </div>
              </Link>
            ))}
          </div>
          <Link
            to="/blog"
            className="not-prose flex rounded-b-2xl bg-neutral-900 p-3 pt-0 no-underline transition-all">
            All Posts →
          </Link>
        </main>
      </div>
      {/* body end */}
    </Layout>
  );
};

export const query = graphql`
  query {
    allMdx(
      limit: 3
      sort: { fields: frontmatter___datePublished, order: DESC }
    ) {
      nodes {
        frontmatter {
          title
          datePublished(formatString: "MMMM D, YYYY")
          heroImage {
            childImageSharp {
              gatsbyImageData
            }
          }
          heroImageAlt
        }
        id
        slug
      }
    }
  }
`;

export default IndexPage;

export const Head = () => <Seo title="Home" />;
